/**
 * Scrapped from cbpAnimatedHeader.js v1.0.0
 */

(function($) {

    var docElem = document.documentElement,
        $header = $('.navbar-default'),
        didScroll = false,
        changeHeaderOn = 300;

    function init() {
        window.addEventListener( 'scroll', function( event ) {
            if( !didScroll ) {
                didScroll = true;
                setTimeout( scrollPage, 250 );
            }
        }, false );
    }

    function scrollPage() {
        var sy = scrollY();
        if ( sy >= changeHeaderOn ) {
            $header.addClass('navbar-shrink');
        }
        else {
            $header.removeClass('navbar-shrink');
        }
        didScroll = false;
    }

    function scrollY() {
        return window.pageYOffset || docElem.scrollTop;
    }

    init();

})(jQuery);