(function($) {

    'use strict';

    // jQuery for page scrolling feature - requires jQuery Easing plugin
    $('a.nav__link').bind('click', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: ($($anchor.attr('href')).offset().top - 50)
        }, 1250, 'easeInOutExpo');
        event.preventDefault();
    });

    // Highlight the top nav as scrolling occurs
    $('body').scrollspy({
        target: '.navbar-fixed-top',
        offset: 51
    })

    // Closes the Responsive Menu on Menu Item Click
    $('.navbar-collapse ul li a').click(function() {
        $('.navbar-toggle:visible').click();
    })

     // Offset for Main Navigation
    $('#main-nav').affix({
        offset: {
            top: 100
        }
    });

    $('.carousel').slick({
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1
    });

    // Initialize WOW.js Scrolling Animations
    new WOW().init();

    $('.service__description').hide();

    $('.service__title').click(function() {
        $(this).next().toggle('slow');
        return false;
    }).next().hide();


})(jQuery);